import { type QueryHookOptions, useQuery } from '@apollo/client';

import { gql } from '@/generated';
import { useFilterConditions } from '@/contexts';
import { type IndexPageQuery, type IndexPageQueryVariables } from '@/generated/graphql';

const QUERY = gql(/* GraphQL */ `
  query IndexPage($filter: [FilterCond]) {
    forestryIndicators(filter: $filter) {
      ...Index_FinancialReturnsFragment
      ...Index_InvestorsRepresentedFragment
      ...Index_InvestmentsRepresentedFragment
      ...Index_AssetClassFragment
      ...Index_GeographicFocusFragment
      ...Index_StageOfBusinessFragment
      ...Index_EcoregionFragment
      ...Index_PresenceOfCertificationsFragment
      ...Index_ImpactStrategyFragment
      ...Index_CountryIncomeGroupFragment
      ...Index_CertificationsPresentFragment
      ...Index_StrategicGoalsFragment
      ...Index_AssetsUnderManagementFragment
      ...Index_StrategicDevelopmentGoalsFragment
      ...Index_RegionsAndCountriesFragment
    }
  }
`);

export function useIndexData(
  options: QueryHookOptions<IndexPageQuery, IndexPageQueryVariables> = {}
) {
  const { benchmark } = useFilterConditions();
  const query = useQuery(QUERY, { variables: { filter: benchmark }, ...options });

  return {
    ...query,
    indicators: query.data?.forestryIndicators,
  };
}
