import { faEllipsisH, faTimes } from '@awesome.me/kit-335a9e77a1/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createFileRoute, Link, Outlet, useNavigate } from '@tanstack/react-router';
import React, { useState } from 'react';
import { BorderBox, Button, Dialog, NavMenu, SectionTitle } from 'ui-v2';
import { colors } from 'ui-v2/theme';
import { useKpiNavigation } from './-hooks/useKpiNavigation';

export const Route = createFileRoute('/impact-kpis/_layout')({
  component: LandManagedLayout,

  notFoundComponent: () => (
    <BorderBox className="flex grow flex-col items-center justify-center gap-4 py-12 sm:py-12">
      <SectionTitle>Page does not exist.</SectionTitle>
      <Button as={Link} to="/impact-kpis" variant="outline">
        Back to index
      </Button>
    </BorderBox>
  ),
});

function LandManagedLayout() {
  const navigate = useNavigate();
  const { title, overview, supportingMetrics } = useKpiNavigation();

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    navigate({ to: '/impact-kpis' });
  };

  return (
    <BorderBox>
      <Dialog isOpen={open} onClose={handleClose} slideFrom="right">
        <Dialog.Overlay onClose={handleClose} />
        <Dialog.Window className="bg-gray-50">
          <Dialog.Close onClose={handleClose}>
            <Link href="/impact-kpis">
              <FontAwesomeIcon icon={faTimes} />
            </Link>
          </Dialog.Close>
          <div className="p-8 lg:p-14">
            {/* header */}
            <div className="mb-7 flex items-center justify-between">
              <SectionTitle>{title}</SectionTitle>
              <Button variant="outline" className="border-none">
                <FontAwesomeIcon icon={faEllipsisH} color={colors.barbie} size="2x" />
              </Button>
            </div>

            {/* navigation */}

            <div className="border-b-2 border-gray-300 pb-1.5">
              <NavMenu layout="desktop">
                <NavMenu.Links>
                  <NavMenu.Link to={overview as any}>Overview</NavMenu.Link>
                  <NavMenu.Link to={supportingMetrics as any}>Supporting Metrics</NavMenu.Link>
                </NavMenu.Links>
              </NavMenu>
            </div>

            <div className="pt-6">
              <Outlet />
            </div>
          </div>
        </Dialog.Window>
      </Dialog>
    </BorderBox>
  );
}
