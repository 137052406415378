import { FC, PropsWithChildren } from 'react';
import { SectionTitle } from '../SectionTitle';
import { cn } from 'utils';

interface VerticalBarTitleProps {
  className?: string;
}

export const VerticalBarTitle: FC<PropsWithChildren & VerticalBarTitleProps> = ({
  children,
  className,
}) => (
  <SectionTitle as="h3" className={cn('text-base leading-4 sm:text-base sm:leading-4', className)}>
    {children}
  </SectionTitle>
);
