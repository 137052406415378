import { FC } from 'react';
import { ChartLegend } from '../ChartLegend';
import { useVerticalBarContext, VerticalBarDatasetKey } from './VerticalBarContext';

interface VerticalBarLegendProps {
  className?: string;
}

const UNSUBMITTED_DATA_LABEL = 'Submit Your Data';

export const VerticalBarLegend: FC<VerticalBarLegendProps> = ({ className }) => {
  const { data, isAllDataMissing } = useVerticalBarContext();

  const legend = data.map(({ label, key, color, groups }) => {
    const isDataUnsubmitted =
      key === VerticalBarDatasetKey.investment &&
      Object.values(groups).every(({ value }) => value === null || value === undefined);

    if (isDataUnsubmitted) {
      return { color, label: UNSUBMITTED_DATA_LABEL };
    }
    return { color, label };
  });

  if (isAllDataMissing) {
    return null;
  }

  return <ChartLegend data={legend} layout="horizontal" className={className} />;
};
