import { useLocation } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

const routes = {
  'land-managed': {
    titleKey: 'landManaged_title',
    overview: '/impact-kpis/land-managed',
    supportingMetrics: '/impact-kpis/land-managed/supporting-metrics',
  },
  'land-protected': {
    titleKey: 'landProtected_title',
    overview: '/impact-kpis/land-protected',
    supportingMetrics: '/impact-kpis/land-protected/supporting-metrics',
  },
  'land-restored': {
    titleKey: 'landRestored_title',
    overview: '/impact-kpis/land-restored',
    supportingMetrics: '/impact-kpis/land-restored/supporting-metrics',
  },
  'scope-1-and-2-emissions': {
    titleKey: 'emissions1And2Removed_title',
    overview: '/impact-kpis/scope-1-and-2-emissions',
    supportingMetrics: '/impact-kpis/scope-1-and-2-emissions/supporting-metrics',
  },
  'emissions-removed': {
    titleKey: 'emissionsRemoved_title',
    overview: '/impact-kpis/emissions-removed',
    supportingMetrics: '/impact-kpis/emissions-removed/supporting-metrics',
  },
  'community-beneficiaries': {
    titleKey: 'forestBeneficiaries_title',
    overview: '/impact-kpis/community-beneficiaries',
    supportingMetrics: '/impact-kpis/community-beneficiaries/supporting-metrics',
  },
  'investee-revenue-growth': {
    titleKey: 'investeeRevenueGrowth_title',
    overview: '/impact-kpis/investee-revenue-growth',
    supportingMetrics: '/impact-kpis/investee-revenue-growth/supporting-metrics',
  },
  'decent-jobs': {
    titleKey: 'decentJobs_title',
    overview: '/impact-kpis/decent-jobs',
    supportingMetrics: '/impact-kpis/decent-jobs/supporting-metrics',
  },
} as const;

export const useKpiNavigation = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const route = Object.keys(routes).find((slug) => location.pathname.includes(slug));

  if (!route) {
    return {
      title: 'KPI Performance',
      overview: '/impact-kpis',
      supportingMetrics: '',
    };
  }

  const currentRoute = routes[route as keyof typeof routes];

  return { ...currentRoute, title: t(currentRoute.titleKey) };
};
