import React, { FC, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
  ReferenceLine,
} from 'recharts';
import { colors } from '../../theme';
import { HelperText } from '../HelperText';
import { Tooltip } from '../Tooltip';
import { DashedBar } from './DashedBar';
import { useVerticalBarChart, type IAxisY } from './useVerticalBarChart';
import { useVerticalBarContext } from './VerticalBarContext';

interface IReferenceLine {
  value: number;
  color?: string;
  label?: string;
}

interface VerticalBarChartProps {
  yAxis: IAxisY;
  referenceLines?: IReferenceLine[];
}

export const VerticalBarChart: FC<VerticalBarChartProps> = ({ yAxis, referenceLines }) => {
  const [hoveredBarIndex, setHoveredBarIndex] = useState<number | null>(null);

  const { data, isAllDataMissing } = useVerticalBarContext();
  const { formattedData, ticksY, formatXTick, formatYTick, paddingsX, setPaddingsX } =
    useVerticalBarChart(yAxis);

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={210} onResize={setPaddingsX}>
      {isAllDataMissing ? (
        <HelperText className="text-red absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          Data not available
        </HelperText>
      ) : (
        <BarChart
          data={formattedData}
          margin={{ top: 8, right: 0, left: -24, bottom: 0 }}
          barCategoryGap={40}
          barSize={40}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} stroke={colors.gray[300]} />

          {referenceLines?.map((line) => (
            <ReferenceLine
              key={line.value}
              y={line.value}
              stroke={line?.color || colors.gray[300]}
              strokeWidth={1}
              strokeDasharray="0"
              {...(line.label && {
                label: {
                  value: line.label,
                  position: 'insideBottomLeft',
                  fill: line?.color || colors.gray[300],
                  fontSize: 10,
                },
              })}
            />
          ))}

          <XAxis
            axisLine={false}
            tickLine={false}
            padding={paddingsX}
            tickFormatter={formatXTick}
            tick={{ fontSize: 10, fontWeight: 600, fill: colors.gray[900] }}
            interval={0}
            dy={6}
          />

          <YAxis
            domain={[yAxis.min, yAxis.max]}
            interval={0}
            axisLine={false}
            tickLine={false}
            ticks={ticksY}
            tickFormatter={formatYTick}
            tick={{ fontSize: 10, fontWeight: 600, fill: colors.gray[500] }}
          />

          <RechartsTooltip
            cursor={{ fill: colors.transparent }}
            isAnimationActive={false}
            content={({ payload }) => {
              if (hoveredBarIndex === null || !payload || !payload.length) {
                return null;
              }

              const currentBar = payload[hoveredBarIndex];

              return (
                <Tooltip variant="static">
                  <Tooltip.Content>
                    <Tooltip.ChartValue
                      label={String(currentBar.name)}
                      value={currentBar.payload.displayValues[currentBar.name!]}
                      color={currentBar.color}
                    />
                  </Tooltip.Content>
                </Tooltip>
              );
            }}
          />

          {data.map((bar, index) => (
            <Bar
              key={bar.label}
              dataKey={bar.label}
              fill={bar.color}
              onMouseOver={() => setHoveredBarIndex(index)}
              onMouseOut={() => setHoveredBarIndex(null)}
              radius={[4, 4, 0, 0]}
              minPointSize={1}
              {...(bar.isDashed && { shape: <DashedBar /> })}
            />
          ))}
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};
