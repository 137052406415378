/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  query GetFilterCounts {\n    forestryIndicators {\n      assetClass {\n        items {\n          count\n          label\n        }\n      }\n      yearsSinceInvestment {\n        items {\n          count\n          label\n        }\n      }\n      stageOfBusiness {\n        items {\n          label\n          count\n        }\n      }\n      incomeGroup {\n        items {\n          label\n          count\n        }\n      }\n      primaryCountryByRegion {\n        items {\n          label\n          count\n        }\n      }\n      impactStrategy {\n        items {\n          label\n          count\n        }\n      }\n      forestType {\n        items {\n          label\n          count\n        }\n      }\n      ecoRegion {\n        items {\n          label\n          count\n        }\n      }\n    }\n  }\n": types.GetFilterCountsDocument,
    "\n  query GetReportingPeriod {\n    forestryIndicators {\n      reportingPeriod {\n        min\n        max\n      }\n    }\n  }\n": types.GetReportingPeriodDocument,
    "\n  query ValidateFilters($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      numberOfOrgs\n    }\n  }\n": types.ValidateFiltersDocument,
    "\n  query GetViewer {\n    me {\n      irisVersion\n      orgId\n      salesforceAccountId\n      userId\n      userName\n    }\n  }\n": types.GetViewerDocument,
    "\n  fragment KPIImpact_DecentJobsFragment on ForestryIndicators {\n    decentJobs {\n      trimmedAvg\n      isDataSuppressed\n    }\n    decentJobs_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    decentJobs_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n": types.KpiImpact_DecentJobsFragmentFragmentDoc,
    "\n  fragment KPIImpact_EmissionRemovedFragment on ForestryIndicators {\n    emissionsScope1n2 {\n      trimmedAvg\n      isDataSuppressed\n    }\n    emissionsScope1n2_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    emissionsScope1n2_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n": types.KpiImpact_EmissionRemovedFragmentFragmentDoc,
    "\n  fragment KPIImpact_EmissionsRemovedFragment on ForestryIndicators {\n    emissionsRemoved {\n      trimmedAvg\n      isDataSuppressed\n    }\n    emissionsRemoved_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    emissionsRemoved_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n": types.KpiImpact_EmissionsRemovedFragmentFragmentDoc,
    "\n  fragment KPIImpact_ForestBeneficiariesFragment on ForestryIndicators {\n    forestBeneficiaries {\n      trimmedAvg\n      isDataSuppressed\n    }\n    forestBeneficiaries_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    forestBeneficiaries_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n": types.KpiImpact_ForestBeneficiariesFragmentFragmentDoc,
    "\n  fragment KPIImpact_InvesteeRevenueGrowthFragment on ForestryIndicators {\n    investeeRevenueGrowth {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n": types.KpiImpact_InvesteeRevenueGrowthFragmentFragmentDoc,
    "\n  fragment KPIImpact_LandManagedFragment on ForestryIndicators {\n    landManaged {\n      trimmedAvg\n      isDataSuppressed\n    }\n    landManaged_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    landManaged_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n": types.KpiImpact_LandManagedFragmentFragmentDoc,
    "\n  fragment KPIImpact_LandProtectedFragment on ForestryIndicators {\n    pctLandProtected {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n": types.KpiImpact_LandProtectedFragmentFragmentDoc,
    "\n  fragment KPIImpact_LandRestoredFragment on ForestryIndicators {\n    pctLandRestored {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n": types.KpiImpact_LandRestoredFragmentFragmentDoc,
    "\n  query ImpactKpisPage($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      ...KPIImpact_LandManagedFragment\n      ...KPIImpact_LandProtectedFragment\n      ...KPIImpact_LandRestoredFragment\n      ...KPIImpact_EmissionsRemovedFragment\n      ...KPIImpact_EmissionRemovedFragment\n      ...KPIImpact_ForestBeneficiariesFragment\n      ...KPIImpact_DecentJobsFragment\n      ...KPIImpact_InvesteeRevenueGrowthFragment\n    }\n  }\n": types.ImpactKpisPageDocument,
    "\n  fragment Index_AssetClassFragment on ForestryIndicators {\n    assetClass {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_AssetClassFragmentFragmentDoc,
    "\n  fragment Index_AssetsUnderManagementFragment on ForestryIndicators {\n    assetsUnderManagement {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n": types.Index_AssetsUnderManagementFragmentFragmentDoc,
    "\n  fragment Index_CertificationsPresentFragment on ForestryIndicators {\n    certifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_CertificationsPresentFragmentFragmentDoc,
    "\n  fragment Index_CountryIncomeGroupFragment on ForestryIndicators {\n    incomeGroup {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_CountryIncomeGroupFragmentFragmentDoc,
    "\n  fragment Index_EcoregionFragment on ForestryIndicators {\n    ecoRegion {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_EcoregionFragmentFragmentDoc,
    "\n  fragment Index_FinancialReturnsFragment on ForestryIndicators {\n    targetFinancialReturns {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_FinancialReturnsFragmentFragmentDoc,
    "\n  fragment Index_GeographicFocusFragment on ForestryIndicators {\n    geographicFocus {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_GeographicFocusFragmentFragmentDoc,
    "\n  fragment Index_ImpactStrategyFragment on ForestryIndicators {\n    impactStrategy {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_ImpactStrategyFragmentFragmentDoc,
    "\n  fragment Index_InvestmentsRepresentedFragment on ForestryIndicators {\n    numberOfInvestees\n  }\n": types.Index_InvestmentsRepresentedFragmentFragmentDoc,
    "\n  fragment Index_InvestorsRepresentedFragment on ForestryIndicators {\n    numberOfOrgs\n  }\n": types.Index_InvestorsRepresentedFragmentFragmentDoc,
    "\n  fragment Index_PresenceOfCertificationsFragment on ForestryIndicators {\n    hasCertifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_PresenceOfCertificationsFragmentFragmentDoc,
    "\n  fragment Index_RegionsAndCountriesFragment on ForestryIndicators {\n    primaryCountryByRegion {\n      items {\n        count\n        label\n        pct\n        items {\n          count\n          label\n          pct\n        }\n      }\n    }\n  }\n": types.Index_RegionsAndCountriesFragmentFragmentDoc,
    "\n  fragment Index_StageOfBusinessFragment on ForestryIndicators {\n    stageOfBusiness {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_StageOfBusinessFragmentFragmentDoc,
    "\n  fragment Index_StrategicDevelopmentGoalsFragment on ForestryIndicators {\n    sdgsTargeted {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_StrategicDevelopmentGoalsFragmentFragmentDoc,
    "\n  fragment Index_StrategicGoalsFragment on ForestryIndicators {\n    strategicGoals {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_StrategicGoalsFragmentFragmentDoc,
    "\n  query IndexPage($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      ...Index_FinancialReturnsFragment\n      ...Index_InvestorsRepresentedFragment\n      ...Index_InvestmentsRepresentedFragment\n      ...Index_AssetClassFragment\n      ...Index_GeographicFocusFragment\n      ...Index_StageOfBusinessFragment\n      ...Index_EcoregionFragment\n      ...Index_PresenceOfCertificationsFragment\n      ...Index_ImpactStrategyFragment\n      ...Index_CountryIncomeGroupFragment\n      ...Index_CertificationsPresentFragment\n      ...Index_StrategicGoalsFragment\n      ...Index_AssetsUnderManagementFragment\n      ...Index_StrategicDevelopmentGoalsFragment\n      ...Index_RegionsAndCountriesFragment\n    }\n  }\n": types.IndexPageDocument,
    "\n  fragment Sdg_DecentJobsFragment on ForestryIndicators {\n    decentJobs_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n": types.Sdg_DecentJobsFragmentFragmentDoc,
    "\n  fragment Sdg_Emissions1And2RemovedFragment on ForestryIndicators {\n    emissionsScope1n2_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n": types.Sdg_Emissions1And2RemovedFragmentFragmentDoc,
    "\n  fragment Sdg_EmissionsRemovedFragment on ForestryIndicators {\n    emissionsRemoved_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n": types.Sdg_EmissionsRemovedFragmentFragmentDoc,
    "\n  fragment Sdg_InvesteeRevenueGrowthFragment on ForestryIndicators {\n    investeeRevenueGrowth {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n": types.Sdg_InvesteeRevenueGrowthFragmentFragmentDoc,
    "\n  fragment Sdg_LandCertifiedFragment on ForestryIndicators {\n    pctLandCertified {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n": types.Sdg_LandCertifiedFragmentFragmentDoc,
    "\n  fragment Sdg_LandRestoredFragment on ForestryIndicators {\n    pctLandRestored {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n": types.Sdg_LandRestoredFragmentFragmentDoc,
    "\n  query SdgPage($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      ...Sdg_LandCertifiedFragment\n      ...Sdg_LandRestoredFragment\n      ...Sdg_Emissions1And2RemovedFragment\n      ...Sdg_EmissionsRemovedFragment\n      ...Sdg_DecentJobsFragment\n      ...Sdg_InvesteeRevenueGrowthFragment\n    }\n  }\n": types.SdgPageDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFilterCounts {\n    forestryIndicators {\n      assetClass {\n        items {\n          count\n          label\n        }\n      }\n      yearsSinceInvestment {\n        items {\n          count\n          label\n        }\n      }\n      stageOfBusiness {\n        items {\n          label\n          count\n        }\n      }\n      incomeGroup {\n        items {\n          label\n          count\n        }\n      }\n      primaryCountryByRegion {\n        items {\n          label\n          count\n        }\n      }\n      impactStrategy {\n        items {\n          label\n          count\n        }\n      }\n      forestType {\n        items {\n          label\n          count\n        }\n      }\n      ecoRegion {\n        items {\n          label\n          count\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetFilterCounts {\n    forestryIndicators {\n      assetClass {\n        items {\n          count\n          label\n        }\n      }\n      yearsSinceInvestment {\n        items {\n          count\n          label\n        }\n      }\n      stageOfBusiness {\n        items {\n          label\n          count\n        }\n      }\n      incomeGroup {\n        items {\n          label\n          count\n        }\n      }\n      primaryCountryByRegion {\n        items {\n          label\n          count\n        }\n      }\n      impactStrategy {\n        items {\n          label\n          count\n        }\n      }\n      forestType {\n        items {\n          label\n          count\n        }\n      }\n      ecoRegion {\n        items {\n          label\n          count\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetReportingPeriod {\n    forestryIndicators {\n      reportingPeriod {\n        min\n        max\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetReportingPeriod {\n    forestryIndicators {\n      reportingPeriod {\n        min\n        max\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ValidateFilters($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      numberOfOrgs\n    }\n  }\n"): (typeof documents)["\n  query ValidateFilters($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      numberOfOrgs\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetViewer {\n    me {\n      irisVersion\n      orgId\n      salesforceAccountId\n      userId\n      userName\n    }\n  }\n"): (typeof documents)["\n  query GetViewer {\n    me {\n      irisVersion\n      orgId\n      salesforceAccountId\n      userId\n      userName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment KPIImpact_DecentJobsFragment on ForestryIndicators {\n    decentJobs {\n      trimmedAvg\n      isDataSuppressed\n    }\n    decentJobs_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    decentJobs_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment KPIImpact_DecentJobsFragment on ForestryIndicators {\n    decentJobs {\n      trimmedAvg\n      isDataSuppressed\n    }\n    decentJobs_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    decentJobs_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment KPIImpact_EmissionRemovedFragment on ForestryIndicators {\n    emissionsScope1n2 {\n      trimmedAvg\n      isDataSuppressed\n    }\n    emissionsScope1n2_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    emissionsScope1n2_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment KPIImpact_EmissionRemovedFragment on ForestryIndicators {\n    emissionsScope1n2 {\n      trimmedAvg\n      isDataSuppressed\n    }\n    emissionsScope1n2_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    emissionsScope1n2_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment KPIImpact_EmissionsRemovedFragment on ForestryIndicators {\n    emissionsRemoved {\n      trimmedAvg\n      isDataSuppressed\n    }\n    emissionsRemoved_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    emissionsRemoved_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment KPIImpact_EmissionsRemovedFragment on ForestryIndicators {\n    emissionsRemoved {\n      trimmedAvg\n      isDataSuppressed\n    }\n    emissionsRemoved_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    emissionsRemoved_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment KPIImpact_ForestBeneficiariesFragment on ForestryIndicators {\n    forestBeneficiaries {\n      trimmedAvg\n      isDataSuppressed\n    }\n    forestBeneficiaries_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    forestBeneficiaries_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment KPIImpact_ForestBeneficiariesFragment on ForestryIndicators {\n    forestBeneficiaries {\n      trimmedAvg\n      isDataSuppressed\n    }\n    forestBeneficiaries_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    forestBeneficiaries_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment KPIImpact_InvesteeRevenueGrowthFragment on ForestryIndicators {\n    investeeRevenueGrowth {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment KPIImpact_InvesteeRevenueGrowthFragment on ForestryIndicators {\n    investeeRevenueGrowth {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment KPIImpact_LandManagedFragment on ForestryIndicators {\n    landManaged {\n      trimmedAvg\n      isDataSuppressed\n    }\n    landManaged_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    landManaged_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment KPIImpact_LandManagedFragment on ForestryIndicators {\n    landManaged {\n      trimmedAvg\n      isDataSuppressed\n    }\n    landManaged_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    landManaged_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment KPIImpact_LandProtectedFragment on ForestryIndicators {\n    pctLandProtected {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment KPIImpact_LandProtectedFragment on ForestryIndicators {\n    pctLandProtected {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment KPIImpact_LandRestoredFragment on ForestryIndicators {\n    pctLandRestored {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment KPIImpact_LandRestoredFragment on ForestryIndicators {\n    pctLandRestored {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ImpactKpisPage($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      ...KPIImpact_LandManagedFragment\n      ...KPIImpact_LandProtectedFragment\n      ...KPIImpact_LandRestoredFragment\n      ...KPIImpact_EmissionsRemovedFragment\n      ...KPIImpact_EmissionRemovedFragment\n      ...KPIImpact_ForestBeneficiariesFragment\n      ...KPIImpact_DecentJobsFragment\n      ...KPIImpact_InvesteeRevenueGrowthFragment\n    }\n  }\n"): (typeof documents)["\n  query ImpactKpisPage($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      ...KPIImpact_LandManagedFragment\n      ...KPIImpact_LandProtectedFragment\n      ...KPIImpact_LandRestoredFragment\n      ...KPIImpact_EmissionsRemovedFragment\n      ...KPIImpact_EmissionRemovedFragment\n      ...KPIImpact_ForestBeneficiariesFragment\n      ...KPIImpact_DecentJobsFragment\n      ...KPIImpact_InvesteeRevenueGrowthFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_AssetClassFragment on ForestryIndicators {\n    assetClass {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_AssetClassFragment on ForestryIndicators {\n    assetClass {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_AssetsUnderManagementFragment on ForestryIndicators {\n    assetsUnderManagement {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_AssetsUnderManagementFragment on ForestryIndicators {\n    assetsUnderManagement {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_CertificationsPresentFragment on ForestryIndicators {\n    certifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_CertificationsPresentFragment on ForestryIndicators {\n    certifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_CountryIncomeGroupFragment on ForestryIndicators {\n    incomeGroup {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_CountryIncomeGroupFragment on ForestryIndicators {\n    incomeGroup {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_EcoregionFragment on ForestryIndicators {\n    ecoRegion {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_EcoregionFragment on ForestryIndicators {\n    ecoRegion {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_FinancialReturnsFragment on ForestryIndicators {\n    targetFinancialReturns {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_FinancialReturnsFragment on ForestryIndicators {\n    targetFinancialReturns {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_GeographicFocusFragment on ForestryIndicators {\n    geographicFocus {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_GeographicFocusFragment on ForestryIndicators {\n    geographicFocus {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_ImpactStrategyFragment on ForestryIndicators {\n    impactStrategy {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_ImpactStrategyFragment on ForestryIndicators {\n    impactStrategy {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_InvestmentsRepresentedFragment on ForestryIndicators {\n    numberOfInvestees\n  }\n"): (typeof documents)["\n  fragment Index_InvestmentsRepresentedFragment on ForestryIndicators {\n    numberOfInvestees\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_InvestorsRepresentedFragment on ForestryIndicators {\n    numberOfOrgs\n  }\n"): (typeof documents)["\n  fragment Index_InvestorsRepresentedFragment on ForestryIndicators {\n    numberOfOrgs\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_PresenceOfCertificationsFragment on ForestryIndicators {\n    hasCertifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_PresenceOfCertificationsFragment on ForestryIndicators {\n    hasCertifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_RegionsAndCountriesFragment on ForestryIndicators {\n    primaryCountryByRegion {\n      items {\n        count\n        label\n        pct\n        items {\n          count\n          label\n          pct\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment Index_RegionsAndCountriesFragment on ForestryIndicators {\n    primaryCountryByRegion {\n      items {\n        count\n        label\n        pct\n        items {\n          count\n          label\n          pct\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_StageOfBusinessFragment on ForestryIndicators {\n    stageOfBusiness {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_StageOfBusinessFragment on ForestryIndicators {\n    stageOfBusiness {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_StrategicDevelopmentGoalsFragment on ForestryIndicators {\n    sdgsTargeted {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_StrategicDevelopmentGoalsFragment on ForestryIndicators {\n    sdgsTargeted {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_StrategicGoalsFragment on ForestryIndicators {\n    strategicGoals {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_StrategicGoalsFragment on ForestryIndicators {\n    strategicGoals {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query IndexPage($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      ...Index_FinancialReturnsFragment\n      ...Index_InvestorsRepresentedFragment\n      ...Index_InvestmentsRepresentedFragment\n      ...Index_AssetClassFragment\n      ...Index_GeographicFocusFragment\n      ...Index_StageOfBusinessFragment\n      ...Index_EcoregionFragment\n      ...Index_PresenceOfCertificationsFragment\n      ...Index_ImpactStrategyFragment\n      ...Index_CountryIncomeGroupFragment\n      ...Index_CertificationsPresentFragment\n      ...Index_StrategicGoalsFragment\n      ...Index_AssetsUnderManagementFragment\n      ...Index_StrategicDevelopmentGoalsFragment\n      ...Index_RegionsAndCountriesFragment\n    }\n  }\n"): (typeof documents)["\n  query IndexPage($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      ...Index_FinancialReturnsFragment\n      ...Index_InvestorsRepresentedFragment\n      ...Index_InvestmentsRepresentedFragment\n      ...Index_AssetClassFragment\n      ...Index_GeographicFocusFragment\n      ...Index_StageOfBusinessFragment\n      ...Index_EcoregionFragment\n      ...Index_PresenceOfCertificationsFragment\n      ...Index_ImpactStrategyFragment\n      ...Index_CountryIncomeGroupFragment\n      ...Index_CertificationsPresentFragment\n      ...Index_StrategicGoalsFragment\n      ...Index_AssetsUnderManagementFragment\n      ...Index_StrategicDevelopmentGoalsFragment\n      ...Index_RegionsAndCountriesFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Sdg_DecentJobsFragment on ForestryIndicators {\n    decentJobs_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Sdg_DecentJobsFragment on ForestryIndicators {\n    decentJobs_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Sdg_Emissions1And2RemovedFragment on ForestryIndicators {\n    emissionsScope1n2_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Sdg_Emissions1And2RemovedFragment on ForestryIndicators {\n    emissionsScope1n2_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Sdg_EmissionsRemovedFragment on ForestryIndicators {\n    emissionsRemoved_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Sdg_EmissionsRemovedFragment on ForestryIndicators {\n    emissionsRemoved_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Sdg_InvesteeRevenueGrowthFragment on ForestryIndicators {\n    investeeRevenueGrowth {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Sdg_InvesteeRevenueGrowthFragment on ForestryIndicators {\n    investeeRevenueGrowth {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Sdg_LandCertifiedFragment on ForestryIndicators {\n    pctLandCertified {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Sdg_LandCertifiedFragment on ForestryIndicators {\n    pctLandCertified {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Sdg_LandRestoredFragment on ForestryIndicators {\n    pctLandRestored {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Sdg_LandRestoredFragment on ForestryIndicators {\n    pctLandRestored {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SdgPage($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      ...Sdg_LandCertifiedFragment\n      ...Sdg_LandRestoredFragment\n      ...Sdg_Emissions1And2RemovedFragment\n      ...Sdg_EmissionsRemovedFragment\n      ...Sdg_DecentJobsFragment\n      ...Sdg_InvesteeRevenueGrowthFragment\n    }\n  }\n"): (typeof documents)["\n  query SdgPage($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      ...Sdg_LandCertifiedFragment\n      ...Sdg_LandRestoredFragment\n      ...Sdg_Emissions1And2RemovedFragment\n      ...Sdg_EmissionsRemovedFragment\n      ...Sdg_DecentJobsFragment\n      ...Sdg_InvesteeRevenueGrowthFragment\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;