import { createFileRoute } from '@tanstack/react-router'
import React from 'react'
import { BorderBox } from 'ui-v2'

export const Route = createFileRoute(
  '/impact-kpis/_layout/investee-revenue-growth/',
)({
  component: InvesteeRevenueGrowthOverview,
})

function InvesteeRevenueGrowthOverview() {
  return <BorderBox>InvesteeRevenueGrowthOverview</BorderBox>
}
