import { createFileRoute } from '@tanstack/react-router'
import React from 'react'
import { BorderBox } from 'ui-v2'

export const Route = createFileRoute(
  '/impact-kpis/_layout/community-beneficiaries/supporting-metrics/',
)({
  component: ForestBeneficiariesSupportingMetrics,
})

function ForestBeneficiariesSupportingMetrics() {
  return <BorderBox>ForestBeneficiariesSupportingMetrics</BorderBox>
}
