import { faAngleDown } from '@awesome.me/kit-335a9e77a1/icons/classic/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { cn } from 'utils';

interface SelectProps<T> {
  options: { value: T; label: string }[];
  value: T;
  onChange: (value: T) => void;
  title?: string;
  className?: string;
}

export const Select = <T,>({ options, className, title, value, onChange }: SelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleSelect = (selectedValue: T) => {
    onChange(selectedValue);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={selectRef} className={cn('relative w-full md:w-[300px]', className)}>
      {/* Header */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="focus:ring-barbie flex w-full items-center justify-between rounded-lg border border-gray-300 px-4 py-1.5 text-left focus:outline-none focus:ring-2"
      >
        <div className="flex flex-col">
          {title && (
            <span className="text-barbie text-[0.625rem] uppercase leading-[0.875rem]">
              {title}
            </span>
          )}
          <span className="text-[0.75rem] font-normal leading-4 text-gray-900">
            {options.find((option) => option.value === value)?.label}
          </span>
        </div>
        <FontAwesomeIcon
          icon={faAngleDown}
          className={cn('text-barbie transform transition-transform duration-200', {
            'rotate-180': isOpen,
          })}
        />
      </button>

      {/* Dropdown */}
      {isOpen && (
        <ul className="absolute z-10 mt-1 w-full rounded-lg border border-gray-300 bg-white shadow-lg">
          {options.map((option) => (
            <li
              key={String(option.value)}
              onClick={() => handleSelect(option.value)}
              className={cn('cursor-pointer px-4 py-2 text-sm text-gray-900 hover:bg-gray-200', {
                'text-barbie font-bold': option.value === value,
              })}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
