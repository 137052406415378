import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';
import { ChartCard, generateChartColors, HorizontalBars, InfoTooltip } from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';
import { useChartTranslations } from '@/hooks/useChartTranslations';

export const Index_StrategicGoalsFragment = gql(/* GraphQL */ `
  fragment Index_StrategicGoalsFragment on ForestryIndicators {
    strategicGoals {
      items {
        count
        label
        pct
      }
      isDataSuppressed
    }
  }
`);

type StrategicGoalsProps = {
  className?: string;
  indicators: FragmentType<typeof Index_StrategicGoalsFragment>;
};

export function StrategicGoals({ indicators, className }: StrategicGoalsProps) {
  const { strategicGoals } = useFragment(Index_StrategicGoalsFragment, indicators);

  const { title, subtitle, chartTooltip, getOptionLabel, getOptionTooltip } =
    useChartTranslations('forestryStrategicGoals');

  const colors = useMemo(
    () => generateChartColors(strategicGoals.items.length),
    [strategicGoals.items.length]
  );

  const sortedItems = useMemo(
    () => orderBy(strategicGoals.items, ['pct'], ['desc']),
    [strategicGoals.items]
  );

  return (
    <ChartCard className={className}>
      <ChartCard.Header>
        <ChartCard.Title>
          {title}
          {chartTooltip && <InfoTooltip className="ml-1">{chartTooltip}</InfoTooltip>}
        </ChartCard.Title>
        {subtitle && <ChartCard.Subtitle>{subtitle}</ChartCard.Subtitle>}
      </ChartCard.Header>
      <ChartCard.Body isDataSuppressed={strategicGoals.isDataSuppressed}>
        <HorizontalBars min={0} max={100} showInlineDisplayValue={false}>
          {sortedItems.map((item, i) => {
            if (!item) return null;
            return (
              <HorizontalBars.Item
                key={i}
                label={getOptionLabel(item.label)}
                tooltipLabel={getOptionTooltip(item.label)}
                value={item.pct}
                displayValue={item.count.toLocaleString()}
                color={colors[i]}
              />
            );
          })}
        </HorizontalBars>
      </ChartCard.Body>
    </ChartCard>
  );
}
