import { faSlashForward } from '@fa-icons/classic/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createFileRoute } from '@tanstack/react-router';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BorderBox, InfoTooltip, SectionTitle, Select } from 'ui-v2';

import { useIsValidFilters } from '@/hooks/useIsValidFilters';
import { InvalidFilters } from '@/components/InvalidFilters';

import { DecentJobs } from './-components/DecentJobs';
import { Emissions1And2Removed } from './-components/Emissions1And2Removed';
import { EmissionsRemoved } from './-components/EmissionsRemoved';
import { ForestBeneficiaries } from './-components/ForestBeneficiaries';
import { InvesteeRevenueGrowth } from './-components/InvesteeRevenueGrowth';
import { LandManaged } from './-components/LandManaged';
import { LandProtected } from './-components/LandProtected';
import { LandRestored } from './-components/LandRestored';
import { useImpactKpisData } from './-hooks/useImpactKpisData';
import { useKpiPerformance } from './-hooks/useKpiPerformance';

export type OptionalKey = 'company' | 'investment' | 'rate_of_change';

const options: { value: OptionalKey; label: string }[] = [
  { value: 'company', label: 'Company' },
  { value: 'investment', label: 'Investment' },
  { value: 'rate_of_change', label: 'Rate of change' },
];

export const Route = createFileRoute('/impact-kpis')({
  component: Impact,
});

function Impact() {
  const { t } = useTranslation();

  const { loading, isValid } = useIsValidFilters();
  const { benchmarkData, viewerData } = useImpactKpisData({ skip: !isValid || loading });

  const [investmentLevel, setInvestmentLevel] = useState<OptionalKey>(options[0].value);

  const relativePerformance = useKpiPerformance({ benchmarkData, viewerData });
  const performanceNode = useMemo(() => {
    if (!relativePerformance) return null;

    const overperformingNode = relativePerformance?.overperforming.length ? (
      <>
        <strong>{t('impactKPIPage_subtitle_overperforming')}</strong> at{' '}
        {relativePerformance.overperforming.length} investments
      </>
    ) : null;

    const performingWithinNode = relativePerformance?.withinBenchmark.length ? (
      <>
        <strong>{t('impactKPIPage_subtitle_performingWithin')}</strong> at{' '}
        {relativePerformance.withinBenchmark.length} investments
      </>
    ) : null;

    const underperformingNode = relativePerformance?.underperforming.length ? (
      <>
        <strong>{t('impactKPIPage_subtitle_underperforming')}</strong> at{' '}
        {relativePerformance.underperforming.length} investments
      </>
    ) : null;

    const nodes = [overperformingNode, performingWithinNode, underperformingNode].filter(Boolean);
    if (nodes.length === 0) return null;

    return (
      <p className="text-gray-900">
        {t('impactKPIPage_subtitle_leadIn')}
        {nodes.map((node, i) => {
          if (i === 0) return <span key={i}> {node}</span>;
          if (i === nodes.length - 1) return <span key={i}> and {node}</span>;
          return <span key={i}>, {node}</span>;
        })}
      </p>
    );
  }, [relativePerformance]);

  if (loading) return null;
  if (!isValid) return <InvalidFilters />;
  if (!benchmarkData) return null;

  const chartProps = {
    indicators: benchmarkData,
    viewerIndicators: viewerData ?? undefined,
    investmentLevel,
  };

  const titleTooltip = t('impactKPIPage_titleTooltip');

  return (
    <BorderBox className="relative">
      {/* header */}
      <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
        <div className="flex flex-wrap items-center gap-1.5">
          <SectionTitle>{t('impactKPIPage_title')}</SectionTitle>
          {titleTooltip && <InfoTooltip>{titleTooltip}</InfoTooltip>}
          {performanceNode && (
            <>
              <div>
                <FontAwesomeIcon
                  icon={faSlashForward}
                  size="sm"
                  className="text-barbie -mr-[3px] rotate-12"
                />
                <FontAwesomeIcon
                  icon={faSlashForward}
                  size="sm"
                  className="text-midnight -ml-0.5 rotate-12"
                />
              </div>
              {performanceNode}
            </>
          )}
        </div>
        <Select
          options={options}
          title="Impact Level"
          value={investmentLevel}
          onChange={setInvestmentLevel}
        />
      </div>
      {/* body */}
      <div className="grid grid-cols-1 gap-6 pt-9 md:grid-cols-2">
        <LandManaged {...chartProps} />
        <LandProtected {...chartProps} />
        <LandRestored {...chartProps} />
        <Emissions1And2Removed {...chartProps} />
        <EmissionsRemoved {...chartProps} />
        <ForestBeneficiaries {...chartProps} />
        <DecentJobs {...chartProps} />
        <InvesteeRevenueGrowth {...chartProps} />
      </div>
    </BorderBox>
  );
}
