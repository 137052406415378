import React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { TextBox } from 'ui-v2';

export const Route = createFileRoute('/acknowledgements')({
  component: Acknowledgements,
});

function Acknowledgements() {
  return (
    <div className="space-y-4">
      <TextBox>
        <TextBox.Paragraph>
          The following organizations contributed data to the Foresrtry Impact Performance
          Benchmark:
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>UNEP*</strong>
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>Verra*</strong>
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>Agroempresa Forestal*</strong>
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>AXA Investment Managers*</strong>
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>EFM Investments & Advisory*</strong>
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>FinnFund*</strong>
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>Fundo Vale*</strong>
        </TextBox.Paragraph>
        <TextBox.Paragraph>MOV Investments</TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>Sonen Capital*</strong>
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>The Conservation Fund*</strong>
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>TriLinc Global, LLC*</strong>
        </TextBox.Paragraph>
        <TextBox.Paragraph className="italic">
          *This benchmark was developed with significant guidance, partnership, and leadership from
          the Benchmark Design Team, whose names are highlighted above
        </TextBox.Paragraph>

        <TextBox.Heading>Sponsors</TextBox.Heading>
        <TextBox.Paragraph>
          The GIIN Impact Lab operates with the financial support of EQT Foundation, Temasek, and
          Visa Foundation. Sorenson Impact Foundation provided additional funding for the forestry
          benchmark.
        </TextBox.Paragraph>
      </TextBox>
    </div>
  );
}
