import { orderBy } from 'lodash-es';
import React, { useMemo } from 'react';
import { ChartCard, generateChartColors, InfoTooltip, PieChart } from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';
import { useChartTranslations } from '@/hooks/useChartTranslations';

export const Index_ImpactStrategyFragment = gql(/* GraphQL */ `
  fragment Index_ImpactStrategyFragment on ForestryIndicators {
    impactStrategy {
      items {
        count
        label
        pct
      }
      isDataSuppressed
    }
  }
`);

type ImpactStrategyProps = {
  className?: string;
  indicators: FragmentType<typeof Index_ImpactStrategyFragment>;
};

export function ImpactStrategy({ indicators, className }: ImpactStrategyProps) {
  const { impactStrategy } = useFragment(Index_ImpactStrategyFragment, indicators);

  const { title, subtitle, chartTooltip, getOptionLabel, getOptionTooltip, getOptionDisplayValue } =
    useChartTranslations('investeePrimaryStrategy');

  const colors = useMemo(
    () => generateChartColors(impactStrategy.items.length),
    [impactStrategy.items.length]
  );

  const pieData = useMemo(
    () =>
      orderBy(
        impactStrategy.items
          .filter((item) => !!item)
          .map((item, index) => ({
            label: getOptionLabel(item?.label),
            tooltipLabel: getOptionTooltip(item?.label),
            value: item?.pct,
            color: colors[index],
            displayValue: getOptionDisplayValue(item.count, { pct: Math.floor(item.pct) }),
          })),
        ['value'],
        ['desc']
      ),
    [colors, impactStrategy.items]
  );

  return (
    <ChartCard className={className}>
      <ChartCard.Header>
        <ChartCard.Title>
          {title}
          {chartTooltip && <InfoTooltip className="ml-1">{chartTooltip}</InfoTooltip>}
        </ChartCard.Title>
        {subtitle && <ChartCard.Subtitle>{subtitle}</ChartCard.Subtitle>}
      </ChartCard.Header>
      <ChartCard.Body isDataSuppressed={impactStrategy.isDataSuppressed}>
        <PieChart data={pieData} showLegend chartPosition="left" />
      </ChartCard.Body>
    </ChartCard>
  );
}
