import { faSdg8 } from '@awesome.me/kit-335a9e77a1/icons/kit/custom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChartCard, InfoTooltip, LabelText, VerticalBar } from 'ui-v2';
import { colors } from 'ui-v2/theme';

import { type FragmentType, gql, useFragment } from '@/generated';
import { useChartData } from '../-hooks/useChartData';

export const Sdg_DecentJobsFragment = gql(/* GraphQL */ `
  fragment Sdg_DecentJobsFragment on ForestryIndicators {
    decentJobs_pacePct {
      average
      median
      n
      isDataSuppressed
    }
  }
`);

type DecentJobsProps = {
  className?: string;
  indicators: FragmentType<typeof Sdg_DecentJobsFragment>;
  viewerIndicators?: FragmentType<typeof Sdg_DecentJobsFragment>;
};

export function DecentJobs({ indicators, viewerIndicators, className }: DecentJobsProps) {
  const { t } = useTranslation();

  const benchmarkFragment = useFragment(Sdg_DecentJobsFragment, indicators);
  const viewerFragment = useFragment(Sdg_DecentJobsFragment, viewerIndicators);

  const benchmarkData = benchmarkFragment.decentJobs_pacePct;
  const viewerData = viewerFragment?.decentJobs_pacePct;

  const { groups, data, yAxisProps } = useChartData({
    viewerData,
    benchmarkData,
    referenceLineValues: [0, 8.5],
  });

  const titleTooltip = t('decentJobs_titleTooltip_sdg');

  return (
    <ChartCard className={className}>
      <ChartCard.Header>
        <div className="flex items-center gap-1.5">
          <FontAwesomeIcon icon={faSdg8} size="2x" className="text-sdg-8" />
          <LabelText>{t('sdgsTargeted_optionLabel_8').replace('8', '8.5')}</LabelText>
        </div>
        <ChartCard.Title>
          {t('decentJobs_title')}
          {titleTooltip && <InfoTooltip className="ml-1">{titleTooltip}</InfoTooltip>}
        </ChartCard.Title>
      </ChartCard.Header>
      <ChartCard.Body isDataSuppressed={benchmarkData.isDataSuppressed}>
        <VerticalBar groups={groups} data={data}>
          <VerticalBar.Chart
            yAxis={yAxisProps}
            referenceLines={[
              { value: 0, color: colors.gray[500] },
              { value: 13.402634, color: colors.barbie, label: 'TARGET (13.4%)' },
            ]}
          />
          <VerticalBar.Legend className="flex items-center justify-center gap-4" />
        </VerticalBar>
      </ChartCard.Body>
    </ChartCard>
  );
}
