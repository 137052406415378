import { FC, PropsWithChildren } from 'react';
import { cn } from 'utils';

interface VerticalBarHeaderProps {
  className?: string;
}

export const VerticalBarHeader: FC<PropsWithChildren & VerticalBarHeaderProps> = ({
  children,
  className,
}) => (
  <header
    className={cn(
      'flex flex-col items-center gap-4 sm:flex-row sm:items-end sm:justify-between',
      className
    )}
  >
    {children}
  </header>
);
