import { useQuery } from '@apollo/client';
import { faTimes } from '@fa-icons/classic/regular';
import { faGripLines } from '@fa-icons/classic/thin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from '@tanstack/react-router';
import React, { useState } from 'react';
import { AuthMenu, DateRange, Dialog, NavMenu, PageTitle } from 'ui-v2';

import { useCMSContext, useFilterConditions } from '@/contexts';
import { gql } from '@/generated';
import { Logo } from '@/icons';
import { type FileRoutesByTo } from '@/router';

import { useViewer } from '@/hooks/useViewer';
import { FilterPanel } from './FilterPanel';

type Route = keyof FileRoutesByTo;
const routesWithFilterPanel: Route[] = ['/', '/impact-kpis', '/sustainable-development-goals'];

const getBaseRoute = (pathname: string) => {
  return `/${pathname.split('/').filter(Boolean)[0] || ''}` as Route;
};

const GET_REPORTING_PERIOD = gql(/* GraphQL */ `
  query GetReportingPeriod {
    forestryIndicators {
      reportingPeriod {
        min
        max
      }
    }
  }
`);

export const Header = () => {
  const {
    home_url,
    submit_data_url,
    sign_in_url,
    sign_out_url,
    settings_url,
    team_url,
    change_password_url,
  } = useCMSContext();
  const { viewer } = useViewer();
  const { viewer: filterViewer } = useFilterConditions();

  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);

  const { pathname } = useLocation();
  const currentBaseRoute = getBaseRoute(pathname);
  const isFilterableRoute = routesWithFilterPanel.includes(currentBaseRoute);

  const { data } = useQuery(GET_REPORTING_PERIOD);
  const reportingPeriod = data?.forestryIndicators?.reportingPeriod;
  const currentYear = new Date().getFullYear();

  const handleRedirect = (url: string) => {
    window.location.href = `${url}`;
  };

  return (
    <>
      {/* container */}
      <header className="sticky top-0 z-10 border-b border-gray-300 bg-white lg:static">
        {/* header */}
        <div className="space-y-6 px-6 py-7 lg:space-y-10 lg:px-20 lg:pb-2 lg:pt-9">
          <div className="flex items-center justify-between">
            <Link to={home_url}>
              <Logo />
            </Link>
            <FontAwesomeIcon
              className="text-barbie lg:hidden"
              icon={faGripLines}
              onClick={() => setIsOpenMobileMenu(true)}
              size="lg"
            />
            <div className="hidden lg:block">
              <AuthMenu
                isLoggedIn={Boolean(viewer?.userId)}
                userName={viewer?.userName ?? ''}
                onSubmit={() => handleRedirect(submit_data_url)}
                onSignIn={() => handleRedirect(sign_in_url)}
                onSignOut={() => handleRedirect(sign_out_url)}
              />
            </div>
          </div>
          {/*date range and benchmark title */}
          <div className="flex items-start gap-4 lg:items-stretch">
            {reportingPeriod && (
              <DateRange
                max={reportingPeriod.max ?? currentYear}
                min={reportingPeriod.min ?? currentYear}
              />
            )}
            <PageTitle
              subheading={!filterViewer ? '' : 'Compare your performance to the benchmark'}
              heading="Forestry Impact Performance Benchmark"
            />
          </div>
          {/* navigation */}
          <div className="relative hidden lg:block">
            <NavMenu layout={'desktop'} activeLink={currentBaseRoute}>
              <NavMenu.Links>
                <NavMenu.Link to="/">Overview</NavMenu.Link>
                <NavMenu.Link to="/impact-kpis">Impact KPIs</NavMenu.Link>
                <NavMenu.Link to="/sustainable-development-goals">
                  Sustainable Development Goals (SDGs)
                </NavMenu.Link>
                <NavMenu.Link to="/methodologies">Methodologies</NavMenu.Link>
                <NavMenu.Link to="/definitions">Definitions</NavMenu.Link>
                <NavMenu.Link to="/acknowledgements">Acknowledgements</NavMenu.Link>
              </NavMenu.Links>
            </NavMenu>
          </div>
        </div>

        {isFilterableRoute && (
          <div className="border-t border-gray-300 px-6 py-4 lg:px-20">
            <FilterPanel />
          </div>
        )}
      </header>

      {/* mobile menu */}
      <Dialog
        isOpen={isOpenMobileMenu}
        onClose={() => setIsOpenMobileMenu(false)}
        slideFrom="right"
      >
        <Dialog.Overlay onClose={() => setIsOpenMobileMenu(false)} />
        <Dialog.Window>
          <Dialog.Close onClose={() => setIsOpenMobileMenu(false)}>
            <FontAwesomeIcon icon={faTimes} size="xs" />
          </Dialog.Close>
          {/* menu */}
          <div className="space-y-7 p-7">
            <div className="flex justify-end">
              <AuthMenu
                isLoggedIn={Boolean(viewer?.userId)}
                userName={viewer?.userName ?? ''}
                onSubmit={() => handleRedirect(submit_data_url)}
                onSignIn={() => handleRedirect(sign_in_url)}
                onSignOut={() => handleRedirect(sign_out_url)}
              />
            </div>
            <NavMenu layout={'mobile'} activeLink={currentBaseRoute}>
              <NavMenu.Links>
                <NavMenu.Link to="/">Overview</NavMenu.Link>
                <NavMenu.Link to="/impact-kpis">Impact KPIs</NavMenu.Link>
                <NavMenu.Link to="/sustainable-development-goals">
                  Sustainable
                  <br />
                  Development Goals (SDG)
                </NavMenu.Link>
                <NavMenu.Link to="/methodologies">Methodologies</NavMenu.Link>
                <NavMenu.Link to="/definitions">Definitions</NavMenu.Link>
                <NavMenu.Link to="/acknowledgements">Acknowledgements</NavMenu.Link>
                <NavMenu.Separator />
                {/* @ts-expect-error external url */}
                <NavMenu.Link to={settings_url}>Settings</NavMenu.Link>
                {/* @ts-expect-error external url */}
                <NavMenu.Link to={team_url}>Team</NavMenu.Link>
                {/* @ts-expect-error external url */}
                <NavMenu.Link to={change_password_url}>Change Password</NavMenu.Link>
                {/* @ts-expect-error external url */}
                <NavMenu.Link to={sign_out_url}>Sign Out</NavMenu.Link>
              </NavMenu.Links>
            </NavMenu>
          </div>
        </Dialog.Window>
      </Dialog>
    </>
  );
};
