import { useMemo } from 'react';
import { colors } from 'ui-v2/theme';

interface UseSortedChartDataProps {
  selectedViewerData?: { trimmedAvg?: number | null };
  selectedBenchmarkData: { trimmedAvg?: number | null };
  getDisplayValue: (value?: number | null) => string;
}

export const useSortedChartData = ({
  selectedViewerData,
  selectedBenchmarkData,
  getDisplayValue,
}: UseSortedChartDataProps) => {
  return useMemo(() => {
    const chartData = [
      {
        label: 'Your Investment',
        value: Number(selectedViewerData?.trimmedAvg),
        displayValue: getDisplayValue(selectedViewerData?.trimmedAvg),
        color: colors.green,
      },
      {
        label: 'Benchmark',
        value: Number(selectedBenchmarkData.trimmedAvg),
        displayValue: getDisplayValue(selectedBenchmarkData.trimmedAvg),
        color: colors.skyblue,
      },
    ];

    return { sortedChartData: chartData };
  }, [selectedViewerData, selectedBenchmarkData]);
};
