import { FC } from 'react';

export const DashedBar: FC<Record<string, any>> = ({ fill, x, y, width, height }) => (
  <>
    <defs>
      <pattern
        id="pattern-stripe"
        width="8"
        height="8"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(45)"
      >
        <rect width="4" height="8" fill="white" />
      </pattern>
      <mask id="mask-stripe">
        <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe)" />
      </mask>
    </defs>

    <rect x={x} y={y} width={width} height={height} fill="white" />
    <rect x={x} y={y} width={width} height={height} fill={fill} mask="url(#mask-stripe)" />
  </>
);
