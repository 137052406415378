import React from 'react';
import { Button, ChartCard, HorizontalBars, InfoTooltip } from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';
import { useChartTranslations } from '@/hooks/useChartTranslations';
import { useSortedChartData } from '../-hooks/useSortedChartData';
import { OptionalKey } from '../route';

export const KPIImpact_LandProtectedFragment = gql(/* GraphQL */ `
  fragment KPIImpact_LandProtectedFragment on ForestryIndicators {
    pctLandProtected {
      trimmedAvg
      isDataSuppressed
    }
  }
`);

type LandProtectedProps = {
  className?: string;
  indicators: FragmentType<typeof KPIImpact_LandProtectedFragment>;
  viewerIndicators?: FragmentType<typeof KPIImpact_LandProtectedFragment>;
  investmentLevel: OptionalKey;
};

export function LandProtected({
  indicators,
  viewerIndicators,
  investmentLevel,
  className,
}: LandProtectedProps) {
  const benchmarkData = useFragment(KPIImpact_LandProtectedFragment, indicators);
  const viewerData = useFragment(KPIImpact_LandProtectedFragment, viewerIndicators);

  const benchmarkDataMap = {
    company: benchmarkData.pctLandProtected,
    investment: benchmarkData.pctLandProtected,
    rate_of_change: benchmarkData.pctLandProtected,
  };

  const viewerDataMap = {
    company: viewerData?.pctLandProtected,
    investment: viewerData?.pctLandProtected,
    rate_of_change: viewerData?.pctLandProtected,
  };

  const selectedBenchmarkData = benchmarkDataMap[investmentLevel];
  const selectedViewerData = viewerDataMap[investmentLevel];

  const { t, title, titleTooltipKPI } = useChartTranslations('landProtected');
  const getDisplayValue = (value?: number | null): string => {
    if (value == null) return '';
    const strValue = (value * 100).toLocaleString('en', { maximumFractionDigits: 0 });
    return t('displayValue', { value: strValue }) ?? strValue;
  };

  const { sortedChartData } = useSortedChartData({
    selectedBenchmarkData,
    selectedViewerData,
    getDisplayValue,
  });

  return (
    <ChartCard className={className}>
      <ChartCard.Header className="flex items-center justify-between">
        <ChartCard.Title>
          {title}
          {titleTooltipKPI && <InfoTooltip className="ml-1">{titleTooltipKPI}</InfoTooltip>}
        </ChartCard.Title>
        {/* <Button variant="outline" className="shrink-0 font-semibold uppercase">
          view details
        </Button> */}
      </ChartCard.Header>
      <ChartCard.Body isDataSuppressed={selectedBenchmarkData.isDataSuppressed}>
        <HorizontalBars min={0} max={1} showInlineDisplayValue={true}>
          {sortedChartData.map((data, index) => (
            <HorizontalBars.Item
              key={index}
              label={data.label}
              value={data.value}
              displayValue={data.displayValue}
              color={data.color}
            />
          ))}
        </HorizontalBars>

        {/* <Link href="/impact-kpis/land-protected" className="mt-5 lg:hidden">
          <Button variant="outline" size="small" className="shrink-0 font-semibold uppercase">
            view details
          </Button>
        </Link> */}
      </ChartCard.Body>
    </ChartCard>
  );
}
