import React, { FC, PropsWithChildren } from 'react';
import { VerticalBarContextProps, VerticalBarContextProvider } from './VerticalBarContext';
import { VerticalBarChart } from './VerticalBarChart';
import { VerticalBarHeader } from './VerticalBarHeader';
import { VerticalBarLegend } from './VerticalBarLegend';
import { VerticalBarTitle } from './VerticalBarTitle';
import { cn } from 'utils';

interface VerticalBarComposition {
  Header: typeof VerticalBarHeader;
  Title: typeof VerticalBarTitle;
  Legend: typeof VerticalBarLegend;
  Chart: typeof VerticalBarChart;
}

interface VerticalBarOwnProps {
  className?: string;
}

export const VerticalBar: FC<VerticalBarContextProps & PropsWithChildren & VerticalBarOwnProps> &
  VerticalBarComposition = ({ groups, data, children, className }) => (
  <VerticalBarContextProvider groups={groups} data={data}>
    <div className={cn('flex flex-col gap-8', className)}>{children}</div>
  </VerticalBarContextProvider>
);

VerticalBar.Header = VerticalBarHeader;
VerticalBar.Title = VerticalBarTitle;
VerticalBar.Legend = VerticalBarLegend;
VerticalBar.Chart = VerticalBarChart;
