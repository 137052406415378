import { createFileRoute } from '@tanstack/react-router'
import React from 'react'
import { BorderBox } from 'ui-v2'

export const Route = createFileRoute(
  '/impact-kpis/_layout/scope-1-and-2-emissions/',
)({
  component: EmissionsOverview,
})

function EmissionsOverview() {
  return <BorderBox>EmissionsOverview</BorderBox>
}
