import React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { InfoTooltip, TextBox } from 'ui-v2';

export const Route = createFileRoute('/definitions')({
  component: Definitions,
});

function Definitions() {
  return (
    <div className="space-y-4">
      <TextBox>
        <TextBox.Heading>Definitions</TextBox.Heading>

        <TextBox.Heading>Investees and stakeholders</TextBox.Heading>
        <TextBox.Paragraph>
          <strong>Investee:</strong> The recipient of investment capital, typically a company,
          project or real asset.
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>Stakeholder:</strong> Sometimes referred to as the beneficiary; the person(s) or
          enterprise(es) that derive advantages from an investment, such as clients, employees,
          businesses, etc.
        </TextBox.Paragraph>

        <TextBox.Heading>Stages of business</TextBox.Heading>
        <TextBox.Paragraph>
          <strong>Seed or start-up:</strong> Business idea exists, but little has been established
          operationally; pre-revenues.
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>Venture:</strong> Operations are established, and the company may or may not be
          generating revenues, but does not yet have positive earnings before interest, tax,
          depreciation and amortization (EBITDA).
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>Growth:</strong> Company has positive EBITDA and is growing.
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>Mature:</strong> Company has stabilized at scale and is operating profitably.
        </TextBox.Paragraph>

        <TextBox.Heading>
          Forest type{' '}
          <InfoTooltip>All definitions from Forestry Stewardship Council (FSC)</InfoTooltip>
        </TextBox.Heading>

        <TextBox.Paragraph>
          <strong>Natural:</strong> A forest area with many of the principal characteristics and key
          elements of native ecosystems, such as complexity, structure and biological diversity,
          including soil characteristics, flora and fauna, in which all or almost all the trees are
          native species, not classified as plantations.
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>Plantation:</strong> A forest area established by planting or sowing with using
          either alien or native species, often with one or few species, regular spacing and even
          ages, and which lacks most of the principal characteristics and key elements of natural
          forests.
        </TextBox.Paragraph>
      </TextBox>

      <TextBox>
        <TextBox.Heading>
          Approaches to forestry management <InfoTooltip>source in brackets</InfoTooltip>
        </TextBox.Heading>
        <TextBox.Paragraph>
          <strong>Protection:</strong> A protected area is a clearly defined geographical space,
          recognised, dedicated and managed, through legal or other effective means, to achieve the
          long term conservation of nature with associated ecosystem services and cultural values.
          Specifically, a protected landscape or protected seascape (ICUN Category V) covers an
          entire body of land or ocean with an explicit natural conservation plan, but usually also
          accommodates a range of for-profit activities. [IUCN]
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>Restoration:</strong> The process of assisting the recovery of an ecosystem that
          has been degraded. Restoration seeks to re-establish the pre-existing ecological structure
          and function, including biotic integrity. Restoration brings an ecosystem back to its
          original state as close as possible, including original flora and fauna and productivity.
          [UNCCD]
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>Afforestation:</strong> Establishment of forest through planting and/or deliberate
          seeding on land that, until then, was under a different land use. Afforestation implies a
          transformation of land use from non-forest to forest. [FAO]
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>Reforestation:</strong> Reforestation refers to the planting of forest on land
          that was forest but has been out of forest cover for a certain duration. For the first
          commitment period of the Kyoto Protocol this was defined as planting on land that did not
          contain forest on December 31, 1989. In North America and in the lead up to post-2012
          negotiations replace this definition with “on land that has been out of forest for at
          least 10 years is a likely alternative. [FAO]
        </TextBox.Paragraph>

        <TextBox.Heading>
          Quality assurance mechanisms for end-users{' '}
          <InfoTooltip>
            These indicate practices taken by the investee in order to ensure the quality of the
            products, goods and services delivered to end connections. The source is indicated in
            brackets.
          </InfoTooltip>
        </TextBox.Heading>
        <TextBox.Paragraph>
          Obtaining product and/or operational certifications [World Bank]
        </TextBox.Paragraph>
        <TextBox.Paragraph>Conducting community needs assessments [USAID]</TextBox.Paragraph>
        <TextBox.Paragraph>Conducting customer satisfaction surveys [MDPI]</TextBox.Paragraph>
        <TextBox.Paragraph>Implementing customer support procedures [World Bank]</TextBox.Paragraph>
        <TextBox.Paragraph>Considering repayment burdens [World Bank]</TextBox.Paragraph>
      </TextBox>

      <TextBox>
        <TextBox.Heading>Miscellaneous</TextBox.Heading>
        <TextBox.Paragraph>
          <strong>Forest beneficiaries:</strong> Number of people benefitting from direct targeting
          of ecosystem services.
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>Living wage:</strong> Remuneration received for a standard work week by a worker
          in a particular time and place sufficient to afford a decent standard of living, including
          food, water, housing, education, healthcare, transport, clothing and other essential
          needs, including provision for unexpected events
        </TextBox.Paragraph>

        <TextBox.Paragraph>
          <strong>Decent jobs:</strong> Decent jobs are those at organizations with each of the
          following policies in place:
        </TextBox.Paragraph>
        <TextBox.Paragraph>Fair Hiring/Recruiting Practices OI1150</TextBox.Paragraph>
        <TextBox.Paragraph>Fair Career Advancement Practices OI4884</TextBox.Paragraph>
        <TextBox.Paragraph>Fair Compensation Practices OI3819</TextBox.Paragraph>
        <TextBox.Paragraph>Fair Dismissal Policy OI9478</TextBox.Paragraph>
        <TextBox.Paragraph>Sexual Harassment Policy OI9088</TextBox.Paragraph>
        <TextBox.Paragraph>Worker Safety OI8001</TextBox.Paragraph>
        <TextBox.Paragraph>Employee Feedback OI3601</TextBox.Paragraph>
        <TextBox.Paragraph>Child Labor Policy OI4432</TextBox.Paragraph>
        <TextBox.Paragraph>Community Service OI4324</TextBox.Paragraph>
        <TextBox.Paragraph>Occupational Injuries OI3757</TextBox.Paragraph>
      </TextBox>
    </div>
  );
}
