import { createFileRoute } from '@tanstack/react-router'
import React from 'react'
import { BorderBox } from 'ui-v2'

export const Route = createFileRoute('/impact-kpis/_layout/emissions-removed/')(
  {
    component: EmissionsRemovedOverview,
  },
)

function EmissionsRemovedOverview() {
  return <BorderBox>EmissionsRemovedOverview</BorderBox>
}
