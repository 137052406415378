import { createFileRoute } from '@tanstack/react-router'
import React from 'react'
import { BorderBox } from 'ui-v2'

export const Route = createFileRoute(
  '/impact-kpis/_layout/land-restored/supporting-metrics/',
)({
  component: LandRestoredSupportingMetrics,
})

function LandRestoredSupportingMetrics() {
  return <BorderBox>LandRestoredSupportingMetrics</BorderBox>
}
