import { createFileRoute } from '@tanstack/react-router'
import React from 'react'
import { BorderBox } from 'ui-v2'

export const Route = createFileRoute('/impact-kpis/_layout/decent-jobs/')({
  component: DecentJobsOverview,
})

function DecentJobsOverview() {
  return <BorderBox>DecentJobsOverview</BorderBox>
}
