import { createFileRoute } from '@tanstack/react-router';
import React from 'react';
import { BorderBox } from 'ui-v2';

export const Route = createFileRoute('/impact-kpis/_layout/land-managed/supporting-metrics/')({
  component: LandManagedSupportingMetrics,
});

function LandManagedSupportingMetrics() {
  return <BorderBox>LandManagedSupportingMetrics</BorderBox>;
}
