import { useMemo } from 'react';
import { isNaValue } from 'utils';

interface KpiPerformanceProp {
  benchmarkData?: any;
  viewerData?: any;
}

const TOLERANCE = 0.1;

export const useKpiPerformance = ({ benchmarkData, viewerData }: KpiPerformanceProp) => {
  return useMemo(() => {
    const overperforming: string[] = [];
    const withinBenchmark: string[] = [];
    const underperforming: string[] = [];

    if (!benchmarkData || !viewerData) return null;

    Object.keys(benchmarkData).forEach((kpi) => {
      const benchmark = benchmarkData[kpi];
      const viewer = viewerData[kpi];

      if (isNaValue(benchmark?.trimmedAvg) || isNaValue(viewer?.trimmedAvg)) return;

      const benchmarkValue = benchmark.trimmedAvg;
      const viewerValue = viewer.trimmedAvg;

      if (viewerValue > benchmarkValue * (1 + TOLERANCE)) {
        overperforming.push(kpi);
      } else if (viewerValue < benchmarkValue * (1 - TOLERANCE)) {
        underperforming.push(kpi);
      } else {
        withinBenchmark.push(kpi);
      }
    });

    return { overperforming, withinBenchmark, underperforming };
  }, [benchmarkData, viewerData]);
};
