import { ApolloProvider } from '@apollo/client';
import { RouterProvider } from '@tanstack/react-router';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { CMSContextProvider } from './contexts';
import createApolloClient from './lib/createApolloClient';
import { router } from './router';
import './i18n.ts';
import './style.css';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ApolloProvider client={createApolloClient()}>
      <CMSContextProvider>
        <RouterProvider router={router} />
      </CMSContextProvider>
    </ApolloProvider>
  </React.StrictMode>
);
