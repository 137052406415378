import React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { TextBox } from 'ui-v2';

export const Route = createFileRoute('/methodologies')({
  component: Methodologies,
});

function Methodologies() {
  return (
    <div className="space-y-4">
      <TextBox>
        <TextBox.Heading>Sample</TextBox.Heading>
        <TextBox.Paragraph>
          Details on the benchmark's sample size and constitution can be found in the benchmark. The
          GIIN will continue to expand the benchmark and welcomes investors to submit data for
          inclusion. If you would like to be represented, please submit your impact results for your
          forestry investments here. Direct forestry investments made with the intention to generate
          measurable social and/or environmental returns alongside a financial return and for which
          capital, engagement or investment terms are used to positively influence targeted impact
          results have been included.
        </TextBox.Paragraph>

        <TextBox.Heading>Methodological approach</TextBox.Heading>
        <TextBox.Paragraph>
          The impact performance benchmark analytic approach aligns to COMPASS: The Methodology for
          Comparing and Assessing Impact. The GIIN team followed four steps to conduct standardized,
          comparable analysis on impact results across investments in forestry, as follows:
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          The impact performance benchmark analytic approach aligns to COMPASS: The Methodology for
          Comparing and Assessing Impact. The GIIN team followed four steps to conduct standardized,
          comparable analysis on impact results across investments in forestry, as follows:
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>1. Define the decision needed:</strong> The GIIN seeks to enable investors to
          incorporate impact into the decision-making process, primarily as it relates to investment
          selection and management allowing investors to understand areas of underperformance and
          outperformance across investments within their forestry portfolios.
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>2. Collect standardized impact information:</strong> The GIIN collected
          information related to investment context, investee context, and impact results from
          investors with forestry impact investments. The GIIN used the IRIS+ platform, a one-stop
          shop for measuring, managing, and optimizing impact to ensure standardization across
          impact data. Specifically, the GIIN relied on the IRIS+ Core Metrics Sets, built in
          alignment with industry standards and key stakeholders, such as the Greenhouse Gas
          Protocol, Science-Based Target Initiatives (SBTI), and the Intergovernmental Panel on
          Climate Change (IPCC).
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>3. Conduct analysis:</strong> The team relied on the IRIS+ evidence base to map
          how forestry metrics link to outcomes within sustainable forestry such as Increasing the
          Conservation of Forests and Forest Resources, Mitigating Carbon Emissions from Forestry
          and Land Use, and others ultimately arriving at eight distinct Key Performance Indicators
          (KPIs) presented in the benchmark. The GIIN subsequently normalized impact results by
          multiplying each impact result by the ratio of investment amount outstanding to enterprise
          value, to arrive at a comparable scale and understand investments' proportional impact
          (i.e., investment-weighted results). When calculating averages or displaying distribution
          charts, the benchmark presents findings excluding outlier investments to ensure
          appropriate interpretation of the findings. To identify outliers, the GIIN team removed
          any data points outside the interquartile range (IQR) multiplied by 100 for various
          segments of data presented. This approach allows for more nuanced interpretations to best
          reflect the insight and impact results across the diverse sample included in the
          benchmark. The GIIN generated two analytic figures: scale and pace, where scale refers to
          the absolute, year-on-year annualized change in impact result and pace refers to the
          annual percent change in impact. Each analytic figure offers an important nuance in
          understanding investment-level impact performance. At this time, data does not allow for
          analysis of impact efficiency, or the amount of impact generated per dollar invested.
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>4.Apply insights:</strong> The GIIN built the impact performance benchmark as a
          series of dashboards to provide insight into aggregate performance and deep dives across
          KPIs, offering investors the opportunity to compare their own impact investment
          performance to peer groups using dynamic filters and to the pace of change required to
          achieve relevant SDGs.
        </TextBox.Paragraph>
      </TextBox>
      <TextBox>
        <TextBox.Heading>Analytic figures presented in the benchmark</TextBox.Heading>
        <TextBox.Paragraph>
          Each KPI presented in the benchmark offers up to four impact performance figures to
          reflect nuanced angles of impact performance.
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>1. Company level impact:</strong> This requires no calculation by the GIIN and is
          directly reported by investors. The three other figures are calculated as follows:
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>2. Investment-weighted figure:</strong> This reflects the normalized (i.e.,
          investment-weighted) impact result associated with a tranche of investment capital as of
          the end of a given reporting year. Investment-weighted results are calculated by
          multiplying a point-in-time impact result by the normalization ratio (i.e., outstanding
          investment amount relative to the enterprise value of the investee). For example, if an
          investee manages sustainable forestry practices on 50,000 hectares of land in 2022, and
          the normalization ratio for that year is 0.4 (based on the investment share in the
          project), the investment-weighted area of sustainable forestry land would be 20,000
          hectares.
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>3. Rate of change (volume):</strong> This reflects the year-on-year volume of
          change in an impact result, based on unweighted impact results. For example, if an
          investee has 50,000 hectares of land in 2022 and 52,500 hectares of land in 2021, then the
          scale of change in volume would be 2,500 additional hectares of land each year.
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>4.Rate of change (percent):</strong> This reflects the year-on-year percent change
          in an impact result. The pace of change as a percentage is calculated using unweighted
          impact results, given the inherent nature of percentages. For example, if an investee
          manages 50,000 hectares of land in 2021 and increases that to 52,500 hectares of land in
          2022, the pace of change is 5 percent.
        </TextBox.Paragraph>

        <TextBox.Heading>Data availability and additional metrics</TextBox.Heading>
        <TextBox.Paragraph>
          As the benchmark grows, the GIIN commits to ongoing product refinements to ensure that
          analytics remain relevant, rigorous, credible and useful. The benchmark presents
          information across KPIs for which sufficient data quantity and quality was made available.
          For each KPI, the benchmark indicates the share of the benchmark sample reporting on a
          given KPI, rationale for missing data, and respondents' confidence in the data submitted.
          However, there are several metrics for which data were unavailable in a sufficient
          quantity (n &lt; 5) to enable meaningful analysis; these metrics will be added into the
          benchmark as and when data volume permits.
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          The primary purpose of the impact performance benchmark is to enable investors to compare
          impact performance relative to peer groups and the pace of change needed to support
          sustainable forestry globally. For this reason, the KPIs presented in the benchmark are
          relevant for comparative purposes. Naturally, investors will also rely on additional
          metrics for their own impact performance management internally and for added context. This
          could include various factors such as forest certifications (e.g., FSC, PEFC),
          biodiversity conservation efforts, carbon sequestration certifications, and the percentage
          of degraded land restored. Other relevant metrics might encompass forest area under
          sustainable management, the number of community livelihoods supported through forestry,
          the volume of sustainably harvested timber, species diversity within managed areas, and
          the extent of agroforestry practices implemented. While these metrics are critical for
          assessing impact performance internally, they may not be suitable for aggregate
          comparisons due to the context required to appropriately interpret each.
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          For a full suite of metrics that investors may track, please see the IRIS+ framework
          builder for sustainable forestry here. Additionally, investors may leverage qualitative
          insights and anecdotes to support and supplement quantitative impact analysis.
        </TextBox.Paragraph>
      </TextBox>

      <TextBox>
        <TextBox.Heading>Sustainable Development Goals (SDG) Comparisons</TextBox.Heading>
        <TextBox.Paragraph>
          In order to compare impact results to the change that's needed to solve our global
          challenges, the GIIN assessed the impact performance across investments relative to the
          Sustainable Development Goal (SDG) targets. Each target offers both a scale and pace
          target. These targets represent the change that's needed globally to achieve a given goal
          by 2030, not the final target itself. Using these targets and third-party data sources,
          including the IEN, the World Bank Open Data, and UNSD, the GIIN determined whether the
          impact performance across investments represented in the benchmark are on-track or
          off-track to meet each SDG target for relevant KPIs.
        </TextBox.Paragraph>
        <TextBox.Paragraph>Target rates are calculated as follows:</TextBox.Paragraph>
        <TextBox.Paragraph className="text-barbie bg-coral-50 mx-auto w-fit rounded px-4 py-3">
          Target annual growth rate = (Target value / Current value)(1 / Years until 2030) - 1
        </TextBox.Paragraph>

        <TextBox.Paragraph>
          The target and current values for each SDG are calculated as follows:
        </TextBox.Paragraph>

        <TextBox.Heading>SDG 8.1.1:</TextBox.Heading>
        <TextBox.Paragraph>
          KPI 8 (Investee revenue growth): To achieve this target, countries designated as “Least
          Developed Countries” must grow per capita GDP at an average of 7% from the inception of
          the SDGs until 2030. Using per-capita GDP data (Source: World Bank) from 2015, the GIIN
          calculated targets for the LDCs. Then, using the most current per-capita GDP data, the
          GIIN calculated the yearly growth rate needed from the current year forward in order to
          average 7% growth from 2015 to 2030.
        </TextBox.Paragraph>

        <TextBox.Heading>SDG 8.5:</TextBox.Heading>
        <TextBox.Paragraph>
          KPI 7 (Decent jobs): To achieve this target, unemployment and underemployment should reach
          zero. Using country level unemployment and underemployment rates from the ILO, the GIIN
          calculated the number of individuals seeking “full and productive employment and decent
          work” as of 2022. To calculate the forestry sector's required contribution to this goal,
          the number of individuals was then multiplied by the percentage of a country's labor force
          that works in forestry using ILO data. This indicator assumes both that the number of
          individuals seeking decent work and the percentage of labor force in forestry will not
          change over time given natural challenges in forecasting these figures.
        </TextBox.Paragraph>

        <TextBox.Heading>SDG 13:</TextBox.Heading>
        <TextBox.Paragraph>
          KPI 4 (Scope 1 & 2 greenhouse gas emissions): Achieving SDG 13 requires a decrease in
          global emissions by 2030. While estimates vary for how much of a decrease is needed to
          achieve to “combat climate change and its impacts,” the GIIN utilized the IPCC's latest
          target to keep warming at or below 1.50C above pre-industrial levels. This requires a 43%
          year on year decrease. Since climate chabge is global, this figure is used globally.
        </TextBox.Paragraph>
      </TextBox>

      <TextBox>
        <TextBox.Heading>SDG 15.1.1:</TextBox.Heading>
        <TextBox.Paragraph>
          Achieving SDG 15.1.1 requires ensuring the conservation, restoration, and sustainable use
          of terrestrial and inland freshwater ecosystems, particularly forests. Although the
          original target year for this goal was 2020, the GIIN aligned its methodology to the
          broader timeline of the SDGs, extending the target year to 2030 to reflect ongoing efforts
          and the majority of SDG deadlines.
        </TextBox.Paragraph>

        <TextBox.Paragraph>
          <strong>KPI 1 (Land sustainably managed):</strong> To assess progress toward this goal,
          the GIIN retrieved country-level data on hectares of forest land and the hectares of
          forest land certified as of 2020 based on the UN’s SDG database. Using this baseline, the
          GIIN calculated the pace of change needed from 2020 to 2030 to achieve 100% certification
          of forest land. This approach assumes that all forest land not yet certified must be
          certified within this timeframe and does not account for potential changes in total forest
          land area or shifts in certification criteria. The yearly rate of certification needed was
          then derived by dividing the remaining uncertified hectares by the years remaining until
          2030.
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          This methodology provides a clear framework for aligning forestry investments with SDG
          15.1.1, offering actionable insights into the pace of progress required to achieve
          sustainable forest management globally.
        </TextBox.Paragraph>
        <TextBox.Paragraph>
          <strong>KPI 3 (Land afforested):</strong> The global goal of restoring 350 million
          hectares (Mha) of lost and degraded forest landscapes by 2030, as outlined by the UN FAO
          to calculate the pace of afforestation required. Using the Global Reforestation Potential
          dataset (2020), the GIIN assessed progress by analyzing the pace of change from 2000 to
          2020, assuming a linear continuation. The remaining hectares needed to reach the 2030
          target were divided by the years left to determine the annual restoration rate required.
          This methodology provides a clear framework for tracking afforestation progress aligned
          with SDG 15.1.1.
        </TextBox.Paragraph>
      </TextBox>
    </div>
  );
}
