export const getYAxisProps = (values: number[]) => {
  const maxValue = Math.ceil(Math.max(...values) / 10) * 10;
  const minValue = Math.floor(Math.min(...values) / 10) * 10;

  const min = Math.min(minValue, maxValue - minValue >= 50 ? 0 : -10);
  const max = Math.max(maxValue, maxValue - minValue >= 50 ? 100 : 25);

  return {
    min,
    max,
    gap: max - min < 50 ? 5 : 10,
    percentage: true,
  };
};
