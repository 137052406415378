import { createFileRoute } from '@tanstack/react-router'
import React from 'react'
import { BorderBox } from 'ui-v2'

export const Route = createFileRoute(
  '/impact-kpis/_layout/land-protected/supporting-metrics/',
)({
  component: LandProtectedSupportingMetrics,
})

function LandProtectedSupportingMetrics() {
  return <BorderBox>LandProtectedSupportingMetrics</BorderBox>
}
