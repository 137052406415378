import { QueryHookOptions, useQuery } from '@apollo/client';

import { gql } from '@/generated';
import { useFilterConditions } from '@/contexts';
import { SdgPageQuery, SdgPageQueryVariables } from '@/generated/graphql';

const QUERY = gql(/* GraphQL */ `
  query SdgPage($filter: [FilterCond]) {
    forestryIndicators(filter: $filter) {
      ...Sdg_LandCertifiedFragment
      ...Sdg_LandRestoredFragment
      ...Sdg_Emissions1And2RemovedFragment
      ...Sdg_EmissionsRemovedFragment
      ...Sdg_DecentJobsFragment
      ...Sdg_InvesteeRevenueGrowthFragment
    }
  }
`);

export function useSdgData(options: QueryHookOptions<SdgPageQuery, SdgPageQueryVariables> = {}) {
  const { viewer, benchmark } = useFilterConditions();
  const benchmarkQuery = useQuery(QUERY, { variables: { filter: benchmark }, ...options });
  const viewerQuery = useQuery(QUERY, { variables: { filter: viewer }, skip: !viewer });

  return {
    loading: benchmarkQuery.loading || viewerQuery.loading,
    benchmarkQuery,
    viewerQuery,
    benchmarkData: benchmarkQuery.data?.forestryIndicators,
    viewerData: viewerQuery.data?.forestryIndicators,
  };
}
