import { useCallback, useMemo, useState } from 'react';
import { useVerticalBarContext } from './VerticalBarContext';

export interface IAxisY {
  min: number; // -10
  max: number; // 25
  gap?: number; // 5
  percentage?: boolean;
}

const Y_TICK_AMOUNT = 8;

export const useVerticalBarChart = (yAxis: IAxisY) => {
  const { data, groups } = useVerticalBarContext();

  const [chartPadding, setChartPadding] = useState(100);
  const paddingsX = { left: chartPadding, right: chartPadding };

  const setPaddingsX = useCallback(
    (width: number) => {
      const newPadding = Math.min(100, width / (groups.length * 2) / 2);
      setChartPadding(newPadding);
    },
    [groups]
  );

  const formattedData = useMemo(
    () =>
      groups.map((group) => {
        const dataPoints = data.reduce(
          (acc, item) => ({
            ...acc,
            [item.label]: item.groups[group.key].value || 0,
            displayValues: {
              ...acc.displayValues,
              [item.label]: item.groups[group.key].displayValue || 0,
            },
          }),
          { displayValues: {} }
        );

        return { name: group.label, ...dataPoints };
      }),
    [groups, data]
  );

  const ticksY = useMemo(() => {
    const amountOfTicks = !yAxis.gap
      ? Y_TICK_AMOUNT - 1
      : Math.floor((yAxis.max - yAxis.min) / yAxis.gap + 1);

    const tickGap = yAxis.gap || Math.floor((yAxis.max - yAxis.min) / amountOfTicks);
    const ticks = [...Array(amountOfTicks)].map((_, index) => yAxis.min + index * tickGap);

    if (!ticks.includes(yAxis.max)) ticks.push(yAxis.max);

    return ticks;
  }, [yAxis.min, yAxis.max, yAxis.gap]);

  const formatXTick = useCallback((index: number) => groups[index].label.toUpperCase(), [groups]);

  const formatYTick = useCallback(
    (tickValue: number) => {
      if (!yAxis.gap && ![yAxis.min, yAxis.max].includes(tickValue)) {
        return '';
      }

      const percentageSign = yAxis?.percentage && tickValue !== 0 ? '%' : '';

      if (Math.abs(tickValue) >= 1000) {
        const thousands = tickValue / 1000;
        const formattedValue =
          String(Math.abs(thousands)).length > 1 ? thousands.toFixed(1) : thousands;
        return `${formattedValue}K${percentageSign}`;
      }

      return tickValue + percentageSign;
    },
    [yAxis.min, yAxis.max, yAxis.gap]
  );

  return {
    formattedData,
    ticksY,
    formatXTick,
    formatYTick,
    paddingsX,
    setPaddingsX,
  };
};
